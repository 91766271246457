import classNames from 'classnames';
import { ChatRoomPartsFragment } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { NotificationOffIcon } from 'ui/icons';
import { notEmpty } from 'utils/typeGuards';
import styles from './ChatRoomAvatar.module.scss';

type Props = {
  chatRoom: ChatRoomPartsFragment;
  size: number;
  isMuted?: boolean;
};
export const ChatRoomAvatars: React.FC<Props> = ({ chatRoom, size, isMuted = false }) => {
  const { tokenDecoded } = useAuthContext();

  if (chatRoom.image) {
    return (
      <div className={styles.containerImage} style={{ width: size, height: size }}>
        <img src={chatRoom.image} alt="Chat room avatar" />
      </div>
    );
  }

  const images = chatRoom.topMembers
    .filter((member) => member.profile.id !== tokenDecoded?.profileId)
    .map((x) => x.profile.image)
    .filter(notEmpty)
    .map((url) => {
      if (url.includes('assets.literal.club')) {
        return url + '?size=100';
      }
      return url;
    });

  return (
    <div className={styles.wrapper}>
      {isMuted && (
        <div className={styles.muted}>
          <NotificationOffIcon />
        </div>
      )}
      <div
        className={classNames(styles.container, {
          [styles.one]: images.length === 1,
          [styles.two]: images.length === 2,
          [styles.three]: images.length === 3,
          [styles.four]: images.length === 4,
        })}
        style={{ width: size, height: size }}
      >
        {images.map((x, i) => (
          <img key={i} src={x} />
        ))}
      </div>
    </div>
  );
};
