import { DropdownMobileAndDesktop } from 'components/basics/Dropdowns/DropdownMobileAndDesktop';
import ReactionButton from 'components/reaction/ReactionButton';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactElement, useRef, useState } from 'react';
import { Maybe } from 'types/generic';
import { ShareImageFormat } from 'types/sharing';
import { ButtonSimple } from 'ui/generic';
import { ShareIcon } from 'ui/icons';
import ShareMenu from './ShareMenu';

interface OptionsCopyItemProps {
  variant?: 'underline' | 'bold' | 'faded' | 'accent' | 'basic';
  shareInfo: {
    path: string;
  };
  renderImagePreview?: ReactElement | undefined | null;
  shareImageUrl?: Maybe<(format: ShareImageFormat) => string | undefined | null>;
  reactionBarButton?: boolean;
  withText?: boolean;
  zIndex?: number;
}

export const ShareContentButton = ({
  reactionBarButton,
  variant = 'basic',
  shareInfo: { path },
  renderImagePreview,
  shareImageUrl,
  withText,
  zIndex,
}: OptionsCopyItemProps): JSX.Element => {
  const [shareMenuOpen, setShareMenuOpen] = useState(false);
  async function share() {
    setShareMenuOpen(true);
  }
  const { smScreen } = useScreenSize();
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <DropdownMobileAndDesktop
      noScroll
      zIndex={zIndex}
      isOpen={shareMenuOpen}
      setOpen={setShareMenuOpen}
      isMobile={smScreen}
      onClickStopPropagation
      button={
        reactionBarButton ? (
          <ReactionButton
            iconSize="24"
            ref={buttonRef}
            onClick={share}
            icon={
              <>
                <ShareIcon /> {withText && <> Share</>}
              </>
            }
          />
        ) : (
          <ButtonSimple ref={buttonRef} iconOnly={!withText} variant={variant} onClick={share}>
            <ShareIcon />
            {withText && <span>Share</span>}
          </ButtonSimple>
        )
      }
    >
      <ShareMenu shareUrlPath={path} renderImagePreview={renderImagePreview} shareImageUrl={shareImageUrl} />
    </DropdownMobileAndDesktop>
  );
};

export default ShareContentButton;
