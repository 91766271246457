import classNames from 'classnames';
import { FadeLoader, HalfPageLoadingSpinner } from 'components/basics/LoadingSpinner';
import {
  ChatRoomMemberStatus,
  useMyChatRoomMembershipsQuery,
  useSendChatMessageMutation,
} from 'generated/graphql';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { ButtonSimple, EmptyState } from 'ui/generic';
import { PlusIcon } from 'ui/icons';
import { Inline } from 'ui/specific/inline/Inline';
import Modal from 'ui/specific/modal/Modal';
import { ModalNegativeMargin } from 'ui/specific/modal/ModalNegativeMargin';
import { QS } from 'utils/queryStringParams';
import { routes } from 'utils/routes';
import { ChatRoomAvatars } from './ChatRoomAvatar';
import styles from './ChatShareModal.module.scss';
import { CreateChatModal } from './CreateChatModal';

type Props = {
  close: () => void;
  shareUrl: string;
};

const pageSize = 20;

export const ChatShareModal = ({ close, shareUrl }: Props) => {
  const {
    data: activeChats,
    loading,
    fetchMore,
  } = useMyChatRoomMembershipsQuery({
    variables: { limit: pageSize, offset: 0, status: ChatRoomMemberStatus.Active },
  });
  const [creating, setCreating] = useState(false);
  const router = useRouter();
  const [sendMessage] = useSendChatMessageMutation();

  return (
    <Modal close={close}>
      <ModalNegativeMargin>
        <div className={classNames('flex flex-row justify-between align-center', styles.headline)}>
          <h1>Share to chat</h1>
          <ButtonSimple onClick={() => setCreating(true)}>
            <PlusIcon />
          </ButtonSimple>
        </div>
        {loading ? (
          <HalfPageLoadingSpinner />
        ) : (
          <>
            {!activeChats || activeChats.myChatRoomMemberships.length === 0 ? (
              <div className="px-5">
                <EmptyState text="No chats here yet"></EmptyState>
              </div>
            ) : (
              <>
                {activeChats.myChatRoomMemberships.map((membership) => (
                  <div
                    key={membership.chatRoomId}
                    onClick={async () => {
                      await sendMessage({
                        variables: { chatRoomId: membership.chatRoomId, content: shareUrl },
                      });
                      router.push(
                        `${routes.chat}?${QS.channel}=${membership.chatRoomId}&${QS.conversation}=1`
                      );
                    }}
                    className={classNames(styles.row)}
                  >
                    <Inline
                      border
                      left={
                        <ChatRoomAvatars
                          key={membership.chatRoomId}
                          size={50}
                          chatRoom={membership.chatRoom}
                        />
                      }
                      top={
                        membership.chatRoom.name ||
                        membership.chatRoom.topMembers.map((x) => x.profile?.name).join(', ')
                      }
                      bottom={undefined}
                    />
                  </div>
                ))}

                <Waypoint
                  onEnter={() => {
                    if (loading) return;

                    fetchMore({
                      variables: {
                        limit: 20,
                        offset: activeChats.myChatRoomMemberships.length,
                      },
                    });
                  }}
                />
                <FadeLoader loading={loading} />
              </>
            )}
          </>
        )}
        {creating && <CreateChatModal close={() => setCreating(false)} redirectToChatAfterCreation={false} />}
      </ModalNegativeMargin>
    </Modal>
  );
};
