import { useRouter } from 'next/router';

export type ShareFormat = 'landscape' | 'square' | 'portrait';

export const useGetShareFormat = (defaultValue?: ShareFormat): ShareFormat => {
  const router = useRouter();

  if (defaultValue) return defaultValue;

  const format = (router.query.format as ShareFormat) || 'landscape';
  return format;
};
