import getConfig from 'next/config';
import { ShareImageFormat } from 'types/sharing';

const { publicRuntimeConfig } = getConfig();

export const getSharableMomentUrl = (
  profileHandle: string,
  bookSlug: string,
  momentId: string,
  format: ShareImageFormat = 'landscape'
): string => {
  const base = `${publicRuntimeConfig.urlBase}/${profileHandle}/book/${bookSlug}/moment/${momentId}`;
  return createUrl(base, { format });
};

export const getSharableMomentImageUrl = (
  handle: string,
  bookSlug: string,
  momentId: string,
  format: ShareImageFormat = 'landscape'
): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/moment/${handle}/${bookSlug}/${momentId}`;
  return createUrl(base, { format });
};

export const getSharableProfileImageUrl = (
  handle: string,
  format: ShareImageFormat = 'landscape'
): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/profile/${handle}`;
  return createUrl(base, { format });
};

export const getSharableBookImageUrl = (slug: string, format: ShareImageFormat = 'landscape'): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/book/${slug}`;
  return createUrl(base, { format });
};

export const getSharableClubImageUrl = (handle: string, format?: ShareImageFormat): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/club/${handle}`;
  return createUrl(base, { format });
};

export const getSharableReviewImageUrl = (
  reviewId: string,
  format: ShareImageFormat = 'landscape'
): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/review/${reviewId}`;
  return createUrl(base, { format });
};

export const getSharableInviteImageUrl = (
  inviteLinkId: string,
  format: ShareImageFormat = 'landscape'
): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/invite/${inviteLinkId}`;
  return createUrl(base, { format });
};

export const getSharableGoalImageUrl = (
  handle: string,
  goalSlug: string,
  progress: number,
  format: ShareImageFormat = 'landscape'
): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/goal/${handle}/${goalSlug}`;
  return createUrl(base, { p: progress.toString(), format });
};

export const getSharableShelfImageUrl = (
  shelfSlug: string,
  handle: string,
  format: ShareImageFormat = 'landscape'
): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/shelf/${handle}/${shelfSlug}`;
  return createUrl(base, { format });
};

export const getSharableWrappedImageUrl = (
  profileId: string,
  format: ShareImageFormat = 'portrait'
): string => {
  const base = `${publicRuntimeConfig.shareMediaUrl}/media/wrapped/${profileId}`;
  return createUrl(base, { format, download: 'true' });
};

function createUrl(base: string | URL, params?: Record<string, string | undefined>): string {
  const url = new URL(base);

  if (params) {
    for (const key in params) {
      const value = params[key];
      if (value) {
        url.searchParams.append(key, value);
      }
    }
  }

  return url.toString();
}
