import { SmallLoadingSpinner } from 'components/basics/LoadingSpinner';
import SearchInput from 'components/layout/SearchInput';
import {
  ChatRoomMemberStatus,
  MyChatRoomMembershipsDocument,
  useCreateChatRoomMutation,
  useSearchProfilesQuery,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Button, Checkbox } from 'ui/generic';
import { UserInline } from 'ui/specific/inline/UserInline';
import Modal from 'ui/specific/modal/Modal';
import { ModalNegativeMargin } from 'ui/specific/modal/ModalNegativeMargin';
import { QS } from 'utils/queryStringParams';
import { routes } from 'utils/routes';

type Props = {
  close: () => void;
  redirectToChatAfterCreation?: boolean;
};

export const CreateChatModal: React.FC<Props> = ({ close, redirectToChatAfterCreation = true }) => {
  const router = useRouter();
  const { profile } = useAuthContext();
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const { data } = useSearchProfilesQuery({
    variables: { query: search },
    skip: !search,
  });

  const [createChatRoom, { loading: creating }] = useCreateChatRoomMutation({});

  const profiles = data?.searchProfiles.filter((x) => x.id !== profile?.id) ?? [];

  return (
    <Modal close={close}>
      <h2 className="displayHeader flex align-left mb-5">Create chat</h2>

      <div className="mb-4" style={{ maxHeight: 50 }}>
        <SearchInput
          searchTerm={search}
          onChange={setSearch}
          onSubmit={setSearch}
          withBorder
          placeholder="Search for friends..."
        />
      </div>
      <ModalNegativeMargin>
        {profiles.map((profile) => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (selected.some((x) => x === profile.id)) {
                setSelected(selected.filter((x) => x !== profile.id));
              } else {
                setSelected([...selected, profile.id]);
              }
            }}
          >
            <UserInline
              border
              key={profile.id}
              profile={profile}
              right={
                <div style={{ pointerEvents: 'none' }}>
                  <Checkbox checked={selected.some((x) => x === profile.id)} />
                </div>
              }
            />
          </div>
        ))}
      </ModalNegativeMargin>

      {selected.length > 0 && (
        <div className="mt-5">
          <Button
            variant="focus"
            disabled={creating}
            icon={creating ? <SmallLoadingSpinner /> : null}
            onClick={async () => {
              const { data: newChatRoom } = await createChatRoom({
                variables: { memberIds: selected, open: false },
                refetchQueries: [
                  {
                    query: MyChatRoomMembershipsDocument,
                    variables: { limit: 20, offset: 0, status: ChatRoomMemberStatus.Active },
                  },
                ],
              });

              if (redirectToChatAfterCreation && newChatRoom?.createChatRoom) {
                router.push(
                  `${routes.chat}?${QS.channel}=${newChatRoom.createChatRoom.id}&${QS.conversation}=1`
                );
              }

              close();
            }}
          >
            Invite {selected.length} people to chat
          </Button>
        </div>
      )}
    </Modal>
  );
};
