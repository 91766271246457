import { Placement } from '@popperjs/core';
import { useScreenSize } from 'hooks/useScreenSize';
import React, { useRef } from 'react';
import { Portal } from 'react-portal';
import Dropdown from './Dropdown';
import MobileDropdown from './MobileDropdown';

type Props = {
  isMobile?: boolean;
  button: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  placement?: Placement;
  isOpen: boolean;
  setOpen: (newVal: boolean) => void;
  padding?: boolean;
  zIndex?: number;
  buttonClassName?: string;
  noScroll?: boolean;

  // Disable event propagation on the button
  onClickStopPropagation?: boolean;
};

export const DropdownMobileAndDesktop = ({
  isMobile,
  button,
  children,
  placement = 'bottom-start',
  isOpen,
  setOpen,
  zIndex = 2,
  buttonClassName,
  noScroll = false,
  onClickStopPropagation = false,
}: Props): JSX.Element => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const { smScreen } = useScreenSize();

  if (isMobile === undefined) {
    isMobile = smScreen;
  }

  if (isMobile) {
    return (
      <>
        <div className={buttonClassName} onClick={() => setOpen(true)}>
          {button}
        </div>
        <MobileDropdown noScroll={noScroll} isOpen={isOpen} closeMe={() => setOpen(false)}>
          {children}
        </MobileDropdown>
      </>
    );
  }

  return (
    <>
      <div
        className={buttonClassName}
        ref={buttonRef}
        onClick={(e) => {
          if (onClickStopPropagation) {
            e.preventDefault();
            e.stopPropagation();
          }
          setOpen(true);
        }}
      >
        {button}
      </div>
      <Portal>
        <Dropdown
          positioningStrategy="absolute"
          placement={placement}
          isOpen={isOpen}
          closeMe={() => setOpen(false)}
          buttonRef={buttonRef}
          padding={false}
          zIndex={zIndex}
        >
          {children}
        </Dropdown>
      </Portal>
    </>
  );
};
