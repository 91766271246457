import throttle from 'lodash.throttle';
import { ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styles from './ResizableElement.module.scss';

type Size = {
  width: number;
  height: number;
};

type Props = {
  initialWidth: number;
  initialHeight: number;
  children: ReactNode;
  maxHeight?: number;
};

// this is used to resize very specifically designed components with set height/width
// eg: share components

const ResizableElement = ({ initialWidth, initialHeight, children, maxHeight }: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<Size | null>(null);

  // const scale = useMemo(() => size?.width && size?.width / initialWidth, [size]);
  const scale = useMemo(() => {
    if (!size) return 1;

    const { width, height } = size;

    if (width === 0 || height === 0) return 1;

    const result =
      initialWidth >= initialHeight
        ? size?.width && size.width / initialWidth
        : size?.height && Math.min(maxHeight || 0, height) / initialHeight;
    return result;
  }, [size]);

  const update = useCallback(
    throttle(function () {
      const width = containerRef.current?.clientWidth;
      const height = containerRef.current?.clientHeight;
      if (width && height) setSize({ width, height });
    }, 0),
    [setSize]
  );

  useLayoutEffect(() => {
    update();
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [initialWidth, initialHeight, maxHeight]);

  const marginBottom = useMemo(() => scale && size && (scale - 1) * size.height, [size, scale]);
  const marginLeft = useMemo(
    () =>
      (initialHeight > initialWidth && size?.width && scale && (size.width - initialWidth * scale) / 2) || 0,
    [initialHeight, scale]
  );

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        marginBottom: `${marginBottom}px`,
        marginLeft: `${marginLeft}px`,
        pointerEvents: 'none',
        userSelect: 'none',
      }}
      className={styles.scaling}
    >
      {children}
    </div>
  );
};

export default ResizableElement;
