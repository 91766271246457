import { default as classNames, default as classnames } from 'classnames';
import ResizableElement from 'components/basics/ResizableElement';
import { ChatShareModal } from 'containers/ChatNew/ChatShareModal';
import copy from 'copy-to-clipboard';
import { useDeviceDetectContext } from 'hooks/useDeviceDetect';
import { useReactNativeContext } from 'hooks/useReactNative';
import getConfig from 'next/config';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import SwiperClass from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Maybe } from 'types/generic';
import { ShareImageFormat } from 'types/sharing';
import { ButtonSimple, Loader } from 'ui/generic';
import { ChatIcon, ConfirmedIcon, DownloadMobileIcon, LinkIcon, ShareIcon } from 'ui/icons';
import { ActionItemType, ActionList } from 'ui/specific/actionList';
import { noop } from 'utils/noop';
import { EventBus, sendRnMessage } from 'utils/reactNative';
import styles from './ShareMenu.module.scss';

type Props = {
  shareUrlPath: string;
  // functions to render components with correct format
  renderImagePreview?: ReactElement | undefined | null;
  shareImageUrl?: Maybe<(format: ShareImageFormat) => string | undefined | null>; // todo
};

const ShareMenu = ({ shareUrlPath, renderImagePreview, shareImageUrl }: Props): JSX.Element => {
  const { canUse } = useReactNativeContext();
  const { publicRuntimeConfig } = getConfig();
  const { isMobileApp, isShareable } = useDeviceDetectContext();
  const [isLoading, setLoading] = useState({ generic: false, save: false });
  const [justCompleted, setJustCompleted] = useState({ generic: false, save: false, url: false });
  const containerRef = useRef<HTMLDivElement>(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [chatShareOpen, setChatShareOpen] = useState(false);
  const shareUrl = `${publicRuntimeConfig.urlBase}${shareUrlPath}`;

  const format: ShareImageFormat = useMemo(() => {
    if (swiperIndex === 1) return 'square';
    else if (swiperIndex === 2) return 'portrait';
    return 'landscape';
  }, [swiperIndex]);

  useEffect(() => {
    if (!swiper) return;
    swiper.slideTo(swiperIndex);
  }, [swiperIndex]);

  const imageUrl = useMemo(() => {
    if (shareImageUrl) return shareImageUrl(format);
  }, [shareImageUrl, format]);

  useEffect(() => {
    if (!canUse.imageSharing) return;
    if (imageUrl) fetch(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    EventBus.$on('SAVE_IMAGE_RESPONSE', (value: boolean) => {
      setLoading({ ...isLoading, save: false });
      if (!value) return;
      setJustCompleted({ ...justCompleted, save: true });
      setTimeout(() => setJustCompleted({ ...justCompleted, save: false }), 2000);
    });

    EventBus.$on('SHARE_IMAGE_BY_URL_GENERIC_RESPONSE', (value: boolean) => {
      setLoading({ ...isLoading, generic: false });
      if (!value) return;
      setJustCompleted({ ...justCompleted, generic: true });
      setTimeout(() => setJustCompleted({ ...justCompleted, generic: false }), 2000);
    });

    return () => {
      EventBus.$off('SAVE_IMAGE_RESPONSE');
      EventBus.$off('SHARE_IMAGE_BY_URL_GENERIC_RESPONSE');
    };
  }, [isLoading, justCompleted]);

  const actionItems: ActionItemType[] = [
    ...(isShareable
      ? [
          {
            title: justCompleted.generic ? 'Shared' : isLoading.generic ? 'Sharing...' : 'Share via...',
            onClick: () => {
              if (isLoading.generic || justCompleted.generic) return;

              if (isMobileApp && imageUrl && canUse.imageSharing) {
                sendRnMessage({ type: 'SHARE_IMAGE_BY_URL_GENERIC', value: imageUrl });
                setLoading({ ...isLoading, generic: true });
              } else {
                window.navigator.share({ url: shareUrl }).catch(noop);
              }
            },
            icon: (
              <ActionItemIconWhenLoading
                idleIcon={<ShareIcon />}
                isLoading={isLoading.generic}
                justCompleted={justCompleted.generic}
              />
            ),
          },
        ]
      : []),

    {
      title: justCompleted.url ? 'Copied' : 'Copy link',
      onClick: () => {
        setJustCompleted({ ...justCompleted, url: true });
        copy(shareUrl);
        setTimeout(() => {
          setJustCompleted({ ...justCompleted, url: false });
        }, 3000);
      },
      icon: (
        <ActionItemIconWhenLoading
          idleIcon={<LinkIcon />}
          isLoading={false}
          justCompleted={justCompleted.url}
        />
      ),
    },
    ...(isMobileApp && imageUrl && canUse.imageSharing
      ? [
          {
            title: justCompleted.save ? 'Saved' : isLoading.save ? 'Saving...' : 'Save to Camera Roll',
            onClick: () => {
              if (isLoading.save || justCompleted.save) return;
              sendRnMessage({ type: 'SAVE_IMAGE', value: imageUrl });
              setLoading({ ...isLoading, save: true });
            },
            icon: (
              <ActionItemIconWhenLoading
                idleIcon={<DownloadMobileIcon />}
                isLoading={isLoading.save}
                justCompleted={justCompleted.save}
              />
            ),
          },
        ]
      : []),
    {
      title: 'Send via chat',
      icon: <ChatIcon />,
      onClick: () => setChatShareOpen(true),
    },
  ];

  const { landscape, square, portrait } = useMemo(() => {
    if (!renderImagePreview) return { landscape: null, square: null, portrait: null };
    return {
      landscape: React.cloneElement(renderImagePreview, { shareFormat: 'landscape' }),
      square: React.cloneElement(renderImagePreview, { shareFormat: 'square' }),
      portrait: React.cloneElement(renderImagePreview, { shareFormat: 'portrait' }),
    };
  }, [renderImagePreview]);

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()} ref={containerRef}>
      {renderImagePreview && isMobileApp && canUse.imageSharing && (
        <>
          <div className={styles.viewerHolder}>
            <div
              className={styles.viewer}
              style={{
                width: containerRef.current?.clientWidth,
              }}
            >
              <Swiper
                onSlideChange={(i) => setSwiperIndex(i.activeIndex)}
                initialSlide={1}
                centeredSlides
                onSwiper={(swiper) => setSwiper(swiper)}
              >
                {/* Slide 1 */}
                <SwiperSlide className={styles.slide}>
                  <div className={styles.slideInner}>
                    <div className={classNames(styles.slideInnerShadow, styles.slide1)}>
                      <ResizableElement maxHeight={266} initialWidth={1200} initialHeight={800}>
                        {landscape}
                      </ResizableElement>
                    </div>
                  </div>
                </SwiperSlide>

                {/* Slide 2 */}
                <SwiperSlide className={styles.slide}>
                  <div className={styles.slideInner}>
                    <div className={classNames(styles.slideInnerShadow, styles.slide2)}>
                      <ResizableElement maxHeight={266} initialWidth={840} initialHeight={840}>
                        {square}
                      </ResizableElement>
                    </div>
                  </div>
                </SwiperSlide>

                {/* Slide 3 */}
                <SwiperSlide className={styles.slide}>
                  <div className={styles.slideInner}>
                    <div className={classNames(styles.slideInnerShadow, styles.slide3)}>
                      <ResizableElement maxHeight={266} initialWidth={1080} initialHeight={1920}>
                        {portrait}
                      </ResizableElement>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className={styles.controls}>
            <ButtonSimple variant="none" onClick={() => setSwiperIndex(0)}>
              <span className={classnames(styles.landscape, { [styles.active]: swiperIndex === 0 })} />
            </ButtonSimple>
            <ButtonSimple variant="none" onClick={() => setSwiperIndex(1)}>
              <span className={classnames(styles.square, { [styles.active]: swiperIndex === 1 })} />
            </ButtonSimple>
            <ButtonSimple variant="none" onClick={() => setSwiperIndex(2)}>
              <span className={classnames(styles.portrait, { [styles.active]: swiperIndex === 2 })} />
            </ButtonSimple>
          </div>
        </>
      )}
      <ActionList items={actionItems} />
      {chatShareOpen && <ChatShareModal close={() => setChatShareOpen(false)} shareUrl={shareUrl} />}
    </div>
  );
};

const ActionItemIconWhenLoading = ({
  justCompleted,
  isLoading,
  idleIcon,
}: {
  isLoading: boolean;
  justCompleted: boolean;
  idleIcon: JSX.Element;
}): JSX.Element => {
  if (justCompleted) return <ConfirmedIcon />;
  else if (isLoading) return <Loader />;
  else return idleIcon;
};

export default ShareMenu;
